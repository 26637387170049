import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-tuscaloosa-alabama.png'
import image0 from "../../images/cities/scale-model-of-downtown-pedal-tours-in-tuscaloosa-alabama.png"
import image1 from "../../images/cities/scale-model-of-children's-hands-on-museum-in-tuscaloosa-alabama.png"
import image2 from "../../images/cities/scale-model-of-paul-w.-bryant-museum-in-tuscaloosa-alabama.png"
import image3 from "../../images/cities/scale-model-of-alabama-museum-of-natural-history-in-tuscaloosa-alabama.png"
import image4 from "../../images/cities/scale-model-of-battle-friedman-house-&-gardens-in-tuscaloosa-alabama.png"
import image5 from "../../images/cities/scale-model-of-connect-crimson-in-tuscaloosa-alabama.png"
import image6 from "../../images/cities/scale-model-of-tuscaloosa---the-one-and-only-in-tuscaloosa-alabama.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Tuscaloosa'
            state='Alabama'
            image={image}
            lat='33.2065'
            lon='-87.5346'
            attractions={ [{"name": "Downtown Pedal Tours", "vicinity": "521 30th Ave, Northport", "types": ["point_of_interest", "establishment"], "lat": 33.2200586, "lng": -87.58551219999998}, {"name": "Children's Hands-On Museum", "vicinity": "2213 University Blvd, Tuscaloosa", "types": ["museum", "travel_agency", "point_of_interest", "establishment"], "lat": 33.2100725, "lng": -87.56615479999999}, {"name": "Paul W. Bryant Museum", "vicinity": "300 Paul W Bryant Dr, Tuscaloosa", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.2066447, "lng": -87.53966969999999}, {"name": "Alabama Museum of Natural History", "vicinity": "427 6th Ave, Tuscaloosa", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.2118705, "lng": -87.54382129999999}, {"name": "Battle-Friedman House & Gardens", "vicinity": "1010 Greensboro Ave, Tuscaloosa", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.2044305, "lng": -87.56673649999999}, {"name": "Connect Crimson", "vicinity": "Tuscaloosa", "types": ["travel_agency", "bar", "point_of_interest", "establishment"], "lat": 33.2110311, "lng": -87.55536440000003}, {"name": "Tuscaloosa - the one and only", "vicinity": "2324 6th St, Tuscaloosa", "types": ["park", "point_of_interest", "establishment"], "lat": 33.2093164, "lng": -87.56452000000002}] }
            attractionImages={ {"Downtown Pedal Tours":image0,"Children's Hands-On Museum":image1,"Paul W. Bryant Museum":image2,"Alabama Museum of Natural History":image3,"Battle-Friedman House & Gardens":image4,"Connect Crimson":image5,"Tuscaloosa - the one and only":image6,} }
       />);
  }
}